/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p,
b,
span {
  font-family: "Quicksand", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background-color: white;
  margin: auto auto;
  transform: translate(-50%, -50%);
  outline: none;
  max-width: 600px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 350ms;
  z-index: 100;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.modal {
  opacity: 0;
  transform: translate(-50%, -55%);
  transition: all 350ms;
}

.ReactModal__Content--after-open.modal {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.ReactModal__Content--before-close.modal {
  opacity: 0;
  transform: translate(-50%, -55%);
}

/* // spinner */
.lds-ring {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #0070f4;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0070f4 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.switch-container input {
  display: none;
}